<template>
  <div class="container mx-auto py-16 pt-0 sm:pt-8">
    <div class="flex flex-col sm:flex-row w-full ">
      <div
        class="p-8 py-2 pb-0 px-2 w-full flex-no-wrap sm:w-64 space-x-6 sm:space-y-4 sm:space-x-0  flex flex-row sm:flex-col text-gray-500 overflow-hidden overflow-x-scroll"
      >
        <router-link
          :to="{ name: 'personal details' }"
          class=" flex-none sm:flex   "
          >Your Account</router-link
        >
        <router-link
          :to="{ name: 'security details' }"
          class=" flex-none sm:flex "
          >Security</router-link
        >
        <router-link
          v-if="hasSubscription"
          :to="{ name: 'billing details' }"
          class=" sm:px-0 flex-none  "
          >Billing</router-link
        >
      </div>
      <router-view
        name="page"
        class="z-10  py-0 pt-8 sm:pt-0 px-2 relative flex-grow"
      />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Settings'
  },
  data() {
    return {};
  },
  computed: {
    hasSubscription: function() {
      return this.$store?.state?.core?.user?.hasSubscription;
    }
  }
};
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply text-black;
}
</style>
